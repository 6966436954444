@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Thin.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-ThinItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Extra";
  src: url("src/metropolis/Metropolis-ExtraLight.woff2") format("woff2"),
    url("src/metropolis/Metropolis-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Extra";
  src: url("src/metropolis/Metropolis-ExtraLightItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Light.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-LightItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Regular.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-RegularItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Medium.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-MediumItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Semi";
  src: url("src/metropolis/Metropolis-SemiBold.woff2") format("woff2"),
    url("src/metropolis/Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Semi";
  src: url("src/metropolis/Metropolis-SemiBoldItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Bold.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-BoldItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Extra";
  src: url("src/metropolis/Metropolis-ExtraBold.woff2") format("woff2"),
    url("src/metropolis/Metropolis-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Extra";
  src: url("src/metropolis/Metropolis-ExtraBoldItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-Black.woff2") format("woff2"),
    url("src/metropolis/Metropolis-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("src/metropolis/Metropolis-BlackItalic.woff2") format("woff2"),
    url("src/metropolis/Metropolis-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
